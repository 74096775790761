import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { Blanket, BlanketContent } from '../../components/Blanket'
import { Container } from '../../components/Container'
import { NewsFeed } from '../../components/NewsFeed'
import { SEO } from '../../components/Seo'
import { WordpressHTML } from '../../components/WordpressHTML'

type AcademyCupPageProps = {
  data: any
}

export default function WeCarePage({ data }: AcademyCupPageProps) {
  return (
    <Blanket dark={false} noPadding>
      <SEO title={data.wordpressPage.title} />

      <Container>
        <BlanketContent>
          <Row>
            <Col xs={{ offset: 2, span: 8 }} md={{ offset: 3, span: 6 }}>
              <GatsbyImage fluid={data.cover?.childImageSharp?.fluid} />
            </Col>
          </Row>
        </BlanketContent>

        <BlanketContent>
          <WordpressHTML content={data.wordpressPage.content} />
        </BlanketContent>

        <BlanketContent>
          <NewsFeed category={'weCare'} />
        </BlanketContent>
      </Container>
    </Blanket>
  )
}

export const query = graphql`
  query WeCarePage($pageId: Int!) {
    cover: file(name: { eq: "230927_Logo We Car3" }) {
      childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 720, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wordpressPage(wordpress_id: { eq: $pageId }) {
      title
      content
    }
  }
`
